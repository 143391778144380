<template>
  <div>Logout</div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  mounted() {
    this.logout();
    this.$store.commit('auth/reset');
    this.$store.commit('user/reset');
    this.$store.commit('npg/reset');
    this.$store.commit('constants/reset');
    this.$store.commit('wizard/reset');
    this.$store.commit('quickActions/reset');
    this.$store.commit('source/reset');
    this.$store.commit('addresses/reset');
    this.$store.commit('notification/reset');
    this.$store.commit('cart/reset');
    this.$store.commit('capabilities/reset');
    this.$router.push({ name: "login" });
  },
  methods: {
    ...mapActions("auth", ["logout"]),
  }
}
</script>